<template>
  <!-- v-if="showPhone" -->
  <aside class="phone">
    <p v-show="callTime">{{ displayCallStatus }} {{ timer }}</p>
    <!-- <sm-phone-input v-model="phoneNumber" />
    <sm-phone-buttons
      v-model="phoneNumber"
      :disabled-call-button="disabledCallButton || !phoneNumber.length"
      @number="onNumber"
      @call="onCall"
      @cancel="onCancel"
    /> -->

    <audio class="visually-hidden" id="mediaElement" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <audio
      class="visually-hidden"
      id="incomingCall"
      src="../../assets/audio/incomingCall.mp3"
      controls
      loop
    >
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <audio
      class="visually-hidden"
      id="outgoingCall"
      src="../../assets/audio/outgoingCall.mp3"
      controls
      loop
    >
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
  </aside>
</template>

<script>
// import SmPhoneInput from '@/components/phone/SmPhoneInput.vue';
// import SmPhoneButtons from '@/components/phone/SmPhoneButtons.vue';

import { UserAgent, Inviter, Registerer } from 'sip.js';

export default {
  name: 'SmPhone',

  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  components: {
    //SmPhoneButtons,
    // SmPhoneInput
  },

  data() {
    return {
      showPhone: false,
      phoneNumber: '',
      // uriCaller: 'sip:501@ats.sm-center.ru',
      mediaElement: null,
      remoteStream: null,
      userAgent: null,
      // invitation: null,
      session: null,
      callTime: 0,
      callStatus: 'none',
      disabledCallButton: false,
    };
  },

  computed: {
    // userAgentOptions() {
    //   return {
    //     ...this.options,
    //     // authorizationUsername: '501',
    //     // authorizationPassword: 'JMVC27o',
    //     contactParams: { transport: 'wss' },
    //     // transportOptions: {
    //     //   server: 'wss://ats.sm-center.ru:7443',
    //     // },
    //   };
    // },

    displayCallStatus() {
      if (this.callStatus === 'incomingCall') {
        return `Входящий от ${this.phoneNumber}`;
      }
      if (this.callStatus === 'outgoingCall') {
        return `Исходящий на ${this.phoneNumber}`;
      }
      return '';
    },

    timer() {
      if (this.callTime) {
        let minutes = parseInt(this.callTime / 60, 10);
        let seconds = parseInt(this.callTime % 60, 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return `${minutes}:${seconds}`;
      }
      return '00:00';
    },
  },

  // created() {
  //   this.authHendler();
  // },

  mounted() {
    this.initServer();
  },

  beforeDestroy() {
    () => this.onCancel();
  },

  methods: {
    // authHendler() {
    //   const auth = prompt('Password');

    //   if (auth == '1289Sm') {
    //     this.showPhone = true;
    //     return;
    //   }

    //   const tryAgain = confirm('Invalid password. Do you want try again?');
    //   if (tryAgain) {
    //     this.authHendler();
    //     return;
    //   }

    //   this.$router.push({ name: 'Home' });
    // },

    initServer() {
      // if (this.showPhone) {
      //

      this.mediaElement = document.getElementById('mediaElement');

      this.mediaElement.addEventListener(
        'timeupdate',
        () => (this.callTime = this.mediaElement.currentTime.toFixed()),
      );

      this.remoteStream = new MediaStream();

      const uri = UserAgent.makeURI(
        `sip:${this.options.authorizationUsername}@ats.sm-center.ru`,
      );

      this.userAgent = new UserAgent({ ...this.options, uri });

      this.userAgent.delegate = {
        onInvite: this.inviteHandler,
      };

      const registerer = new Registerer(this.userAgent);

      this.userAgent.start().then(() => {
        registerer.register();
      });

      //
      // }
    },

    inviteHandler(invitation) {
      this.callStatus = 'incomingCall';

      // this.invitation = invitation;
      this.session = invitation;

      document.getElementById('incomingCall').play();

      this.$root
        .$confirmModal({
          message: `Входящий звонок от ${this.session.incomingInviteRequest.message.from._displayName}`,
          leftButtonText: 'Принять',
          rightButtonText: 'Отклонить',
        })
        .then((response) => {
          document.getElementById('incomingCall').pause();
          document.getElementById('incomingCall').currentTime = 0;
          if (response) {
            // this.invitation.stateChange.addListener((state) => {
            this.session.stateChange.addListener((state) => {
              switch (state) {
                case 'Initial':
                  break;
                case 'Establishing':
                  break;
                case 'Established':
                  this.setupRemoteMedia(invitation);
                  break;
                case 'Terminating':
                // fall through
                case 'Terminated':
                  this.cleanupMedia();
                  break;
                default:
                  this.$notify({
                    header: 'Unknown session state.',
                    type: 'error',
                    timer: 5000,
                  });
              }
            });
            // this.invitation.accept();
            this.session.accept();
            return;
          }

          return invitation.reject();
        });
    },

    onNumber(number) {
      this.phoneNumber = this.phoneNumber + number;
    },

    onCall() {
      this.disabledCallButton = true;
      this.callStatus = 'outgoingCall';
      // this.userAgent.start().then(() => {
      const target = UserAgent.makeURI(
        `sip:${this.phoneNumber}@ats.sm-center.ru`,
      );

      const inviter = new Inviter(this.userAgent, target);

      this.session = inviter;

      document.getElementById('outgoingCall').play();
      // document.getElementById('outgoingCall').loop = true;

      inviter.stateChange.addListener((state) => {
        switch (state) {
          case 'Initial':
            break;
          case 'Establishing':
            break;
          case 'Established':
            this.setupRemoteMedia(inviter);
            document.getElementById('outgoingCall').pause();
            document.getElementById('outgoingCall').currentTime = 0;
            break;
          case 'Terminating':
          // fall through
          case 'Terminated':
            document.getElementById('outgoingCall').pause();
            document.getElementById('outgoingCall').currentTime = 0;
            this.cleanupMedia();
            this.cleanSession();
            break;
          default:
            this.$notify({
              header: 'Unknown session state.',
              type: 'error',
              timer: 5000,
            });
        }
      });

      inviter.invite();
      // });
    },

    onCancel() {
      this.disabledCallButton = false;
      this.phoneNumber = '';
      this.callTime = 0;
      this.callStatus = 'none';

      if (!this.session) return;

      const state = this.session.state;

      switch (state) {
        case 'Initial':
        case 'Establishing':
          if (this.session instanceof Inviter) {
            // An unestablished outgoing session
            this.session.cancel();
            this.cleanSession();
          } else {
            // An unestablished incoming session
            this.session.reject();
            this.cleanSession();
          }
          break;
        case 'Established':
          // An established session
          this.session.bye();
          this.cleanSession();
          break;
        case 'Terminating':
        case 'Terminated':
          // Cannot terminate a session that is already terminated
          break;
      }
    },

    setupRemoteMedia(session) {
      session.sessionDescriptionHandler.peerConnection
        .getReceivers()
        .forEach((receiver) => {
          if (receiver.track) {
            this.remoteStream.addTrack(receiver.track);
          }
        });
      this.mediaElement.srcObject = this.remoteStream;
      this.mediaElement.play();
    },

    cleanupMedia() {
      this.mediaElement.srcObject = null;
      this.mediaElement.pause();
    },

    cleanSession() {
      this.session = null;
    },
  },
};
</script>

<style lang="scss">
.phone {
  width: 180px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
</style>
